import React from "react";
import Layout from "../core/Layout";
import { makeStyles } from "@material-ui/core/styles";
import NewsletterRow from "./NewsletterRow";
import { StaticImage } from "gatsby-plugin-image";

export default function SubscribePage() {
  const classes = useStyles();
  return (
    <>
      <Layout
        maxWidth="md"
        pageTitle="Subscribe to JetpackCompose.app, The #1 website for all things Jetpack Compose!"
        pageDescription="Subscribe and get early access to deep dives, examples, tips and goodies about Jetpack Compose"
        pageSlug="/subscribe"
        pageImageUrl="/subscribe_poster.png"
        seoImageUrl="/subscribe_poster.png"
      >
        <div className={classes.welcomeImageSection}>
          <StaticImage
            src="../../images/mo_welcome.png"
            className={classes.welcomeImage}
            alt="Welcome to the VIP club"
            placeholder="blurred"
            height={500}
            width={500}
            transformOptions={{ fit: "inside" }}
          />
        </div>
        <div className={classes.newsletterSection}>
          <NewsletterRow />
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  newsletterSection: {
    marginBottom: 32,
  },
  welcomeImageSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 32,
  },
  welcomeImage: {
    maxWidth: 500,
  },
});
